import { useContext } from 'react'
import { Typography, Row, Col, Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'
import PosthogHelper from '../../helpers/posthog.helper'
import { UserContext } from '../../contexts/user.context'
import "./latestPosts.scss"

const { Paragraph } = Typography

const LatestPosts = () => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const blogs = [{
    id: `cac`,
    title: `How a CAC Test Could Save Your Life`,
    description: <>
      The Coronary Artery Calcium (CAC) test is a powerful, non-invasive tool for assessing your risk of heart disease. It's a quick scan that measures the calcium buildup in the arteries of your heart, an early indicator of atherosclerosis—a condition that leads to heart disease. By providing a CAC score, the test offers a clear picture of your future risk of cardiovascular events, such as heart attacks. The higher your CAC score, the more calcified plaque you have in your arteries, increasing your heart disease risk. On the other hand, a score of zero indicates a low risk of heart disease.
    </>,
    publishedAt: 'Sep 6, 2024',
    readTime: "4 Min Read",
    photo: "/img/cac.jpg",
  }, {
    id: `galleri`,
    title: `Is the Galleri test worth it?`,
    description: <>
      Early detection of cancer significantly improves survival rates. The Galleri test requires only a simple blood draw and scans for over 50 types of cancer. Known for its high specificity, the test does, however, have limitations in sensitivity, particularly in the early stages of cancer. Nevertheless, it offers invaluable information into potential cancer threats, especially for those genetically predisposed to the disease.
    </>,
    publishedAt: 'Dec 20, 2023',
    readTime: "5 Min Read",
    photo: "/img/galleri.png",
  }, {
    id: `insurance`,
    title: `Maximize Your Health Savings with Instalab: Using HSA and FSA to Stay Proactive`,
    description: <>
      Health Savings Accounts (HSAs) and Flexible Spending Accounts (FSAs) are powerful tools to help you manage healthcare costs while focusing on proactive health care. By understanding how these accounts work and how you can leverage them for services like those provided by Instalab, you can make significant strides in your health journey—without breaking the bank. Here's how you can make the most of your HSA and FSA, especially with the services available through Instalab.
    </>,
    publishedAt: 'Oct 15, 2024',
    readTime: "4 Min Read",
    photo: "/img/hsa-fsa.jpg",
  }, {
    id: `founder`,
    title: `Founder Health: The Silent Sacrifice in the Pursuit of Success`,
    description: <>
      Starting a startup often means pushing health to the back burner. In the race to success, it's easy to think "I'll deal with my health when my startup succeeds." But "success" turns out to be a moving goal post. From raising the next big round, to making that pivotal hire, to achieving a growth milestone, to navigating a board issue. It's never ending. Your health is perpetually sidelined.
    </>,
    publishedAt: 'Nov 5, 2023',
    readTime: "4 Min Read",
    photo: "/img/blogs/founder.jpg",
  }]

  return (
    <div className="latest-posts">
      <div className="posts-header">
        <Paragraph className="posts-header-text">
          Read Latest Posts
        </Paragraph>
      </div>

      <div className="post-list">
        <Row gutter={20}>
          {blogs?.map(({ id, title, description, photo, publishedAt, readTime }) => (
            <Col 
              key={`blog-${id}`}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <div 
                className="post-item"
                onClick={() => {
                  PosthogHelper.track(currentUser, `[research] click on post: ${id}`, true)
                  navigate(`/blog/${id}`)
                }}
              >

                <div className="post-image-container">
                  <img 
                    src={photo} 
                    className="post-image"
                  />

                  <Skeleton.Avatar 
                    active
                    shape='square' 
                    className="image-skeleton"
                  />
                </div>

                <Paragraph 
                  className="post-title"
                  ellipsis={{
                    rows: 1
                  }}
                >
                  {title}
                </Paragraph>

                <Paragraph 
                  className="post-content" 
                  ellipsis={{
                    rows: 3
                  }}
                >
                  {description}
                </Paragraph>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default LatestPosts