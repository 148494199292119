import { Typography } from "antd";
import "./hypothyroidismAdvice.scss";
import {TestView} from "../testView/testView.component"
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import { TestCode, ReportSection, QuestionTemplateCode, Supplement, LowercaseTest, Medication, Condition } from "../../../enums/index.enum"

const converter = require("number-to-words");
const { Paragraph } = Typography;
const primaryCodes = [TestCode.TSH, TestCode.FREET4, TestCode.FREET3, TestCode.TPO_ANTIBODY]

export const HypothyroidismAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [primaryTests, setPrimaryTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [recParams, setRecParams] = useState();

  const hasIsolatedAntiTpo = report?.result.conditions?.includes(Condition.ISOLATED_HIGH_ANTI_TPO)

  useEffect(() => {
    categorizeTests()
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setRecParams(getRecParams());
  }, [report, primaryTests])

  useEffect(() => {
    setDescription(getDescription())
  }, [report])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  const categorizeTests = () => {
    if (!report || !tests) return null;
    const thyroidTests= ReportHelper.getSectionSuboptimalTests(report, ReportSection.HYPOTHYROIDISM_ADVICE, tests)|| null;
    console.log("thyroidTests", thyroidTests)
    setPrimaryTests(
      thyroidTests.filter(({ _id, code }) => 
        tests.map(test => test._id).includes(_id) && primaryCodes.includes(code)
      )
    );    // setSecondaryTests(tests.filter(({_id, code}) => thyroidTests.includes(_id) && primaryCodes.includes(code) ))
  };


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !primaryTests) return null;

    const medications = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_MEDICATIONS)
    const isTakingThyroidMedication = medications?.includes(Medication.THYROID)
    
    // Check for isolated Anti-TPO antibodies
    if (hasIsolatedAntiTpo) {
      return <>You have <span>elevated anti-TPO levels</span>, which increases your risk for <span>hypothyroidism</span>.</>;
    }

    return (
      <>
      {isTakingThyroidMedication 
      
        ? <>Your <span>{LowercaseTest[primaryTests[0].code]}</span> is <span>{ReportHelper.getRiskEval(TestCode.TSH, report, results, tests)}</span>, despite taking thyroid medication.</>  
        : <>
        {primaryTests.length === 1 
          ? <>Your <span>{LowercaseTest[primaryTests[0].code]}</span> is <span>{ReportHelper.getRiskEval(TestCode.TSH, report, results, tests)}</span>, which suggests</>
          : <>These{" "}<span>{converter.toWords(primaryTests.length)} biomarkers</span>{" "} indicate</>}
          {" "}possible <span>hypothyroidism</span>.
        </>     
        }
      </>
    )
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)
    if (!report) return null;
    let content;

    if (hasIsolatedAntiTpo)
      content = <>Elevated anti-TPO antibodies may signal early autoimmune thyroiditis (Hashimoto’s disease), the leading cause of hypothyroidism, which can result in fatigue, weight gain, and cold intolerance.
      <br/><br/>The good news is that your TSH, free T4, and free T3 are normal, so treatment is not needed. However, regular thyroid function monitoring is recommended to detect any changes and enable early intervention if hypothyroidism develops.</>
    else 
      content = "Hypothyroidism is a condition characterized by an underactive thyroid gland and can significantly slow down the body's metabolic processes, affecting various bodily functions. Symptoms include fatigue, weight gain, and cold intolerance."

    return content;
  }

  const getRecParams = () => {
    if (!report || !primaryTests) return null;
    const thyroidTestCodes = primaryTests.map(test => test.code);
    const fullThyroidPanel = [TestCode.TSH, TestCode.FREET4, TestCode.FREET3, TestCode.TPO_ANTIBODY, TestCode.THYROGLOBULIN_ANTIBODY]
    const didFullThyroidPanel = fullThyroidPanel.every(code => thyroidTestCodes.includes(code))
    const supplements = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_SUPPLEMENTS)

    let params = {
      didFullThyroidPanel: didFullThyroidPanel,
      isTakingBiotin: supplements?.includes(Supplement.BIOTIN)
    }
  
    return params
  };

  
  return (
    primaryTests && recParams && (
      <div className="hypothyroidism-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestView 
          report={report} 
          tests={primaryTests}
          results={results}
          reports={reports}
          user={user}
        />

        <AdviceSection 
          report={report} 
          section={section}
          results={results}
          tests={tests}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
