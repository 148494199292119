import { Form, Button, Row, Col, Radio } from "antd"
import "./giftSelect.scss"
import { WarningFilled, } from '@ant-design/icons'
import { FlowStepFooter } from "../../flowStep/flowStepFooter.component"
import { useState, useEffect, useRef, useContext } from "react"
import FormHelper from "../../helpers/form.helper"
import RuleHelper from "../../../../helpers/rule.helper"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from 'dayjs'
import { listProductTypes } from "../../../../services/productType.service"
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import "../singleSelect/singleSelect.scss" // Import the single select styles
import { UserContext } from "../../../../contexts/user.context"

const { Item } = Form

export const GiftSelect = ({
  step,
  onSubmit,
  skeleton,
  form,
  isSubmitting,
  hasAttempt,
  flow,
}) => {
  const navigate = useNavigate()
  const [rules, setRules] = useState({})
  const { flowType } = useParams()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  const [productTypes, setProductTypes] = useState()
  const { currentUser } = useContext(UserContext)
  useEffect(() => {
    fetchRules()
  }, [step])

  useEffect(() => {
    fetchProductTypes()


    const keyDownHandler = event => {
      if (event.target.classList.contains('__PrivateStripeElement-input')) {
        return;
      }
      
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      form.resetFields();
    };
  }, []);

  useEffect(() => {
    if (flow?.giftProductTypeCodes) {
      form.setFieldValue('giftProductTypeCodes', flow?.giftProductTypeCodes[0]);
    }
  }, [flow])

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const selectedGift = getProductType(values.giftProductTypeCodes);
      if (selectedGift) {
        onSubmit(values);
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.GIFT_GALLERI,
            ProductTypeCode.GIFT_LONGEVITY_PANEL,
            ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW,
            ProductTypeCode.GIFT_TESTOSTERONE_PANEL,
            ProductTypeCode.GIFT_FERTILITY_PANEL, 
            ProductTypeCode.GIFT_HEART_HEALTH_PANEL
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType?.code === code)
  }

  const fetchRules = () => {
    let r = {}

    defaultFields.forEach(group => {
      group.fields.forEach(field => {
        let fieldRules = []

        if (field.required) {
            fieldRules.push({ ...RuleHelper.isRequired, message: <><WarningFilled />&nbsp; Please select an option</> })
        }

        r[field.name] = fieldRules
      })
    })
    setRules(r)
  }


  const defaultFields = [
    {
      group: 'Choose a gift',
      fields: [
        {
          name: 'giftProductTypeCodes',
          required: true,
          options: [
            {
              title: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.title,
              value: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.code,
              price: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.title,
              value: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.code,
              price: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_GALLERI)?.title,
              value: getProductType(ProductTypeCode.GIFT_GALLERI)?.code,
              price: getProductType(ProductTypeCode.GIFT_GALLERI)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_GALLERI)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.title,
              value: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.code,
              price: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.title,
              value: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.code,
              price: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.title,
              value: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.code,
              price: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.tagline
            }
          ]
        }
      ]
    }
  ]

  const renderField = (field) => {
    return (
      <Radio.Group
        value={form.getFieldValue('giftProductTypeCodes')}
        onChange={(e) => {
          form.setFieldValue('giftProductTypeCodes', e.target.value);
          if (hasAttemptRef.current) {
            FormHelper.fetchHasError(form)
          }
          handleSubmit();           
        }}
      >
        <Row gutter={[18, 18]}>
          {field.options.map(({ value, title, tagline, price }) => (
            <Col sm={24} md={12} key={value} className="product-grid-item">
              <Radio 
                value={value} 
                className={Array.isArray(form.getFieldValue('giftProductTypeCodes')) 
                  ? form.getFieldValue('giftProductTypeCodes')?.includes(value) ? 'selected' : ''
                  : form.getFieldValue('giftProductTypeCodes') === value ? 'selected' : ''
                }
                checked={Array.isArray(form.getFieldValue('giftProductTypeCodes')) 
                  ? form.getFieldValue('giftProductTypeCodes')?.includes(value)
                  : form.getFieldValue('giftProductTypeCodes') === value
                }
              >
                <div className="product-select-label">
                  {getProductType(value)?.photos.length > 0 && (
                    <div className="option-image">
                      <img src={getProductType(value)?.photos[0]?.webp} alt={title} />
                    </div>
                  )}
                  <div className="option-title-container">
                    <div className="option-title">{title}</div>
                    <div className="option-description">{tagline}</div>
                  </div>
                  <div className="price-button-container">
                    <div className="option-price">${price}</div>
                    <Button 
                      type="default" 
                      className="select-gift-button"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop button click from interfering
                        form.setFieldValue('giftProductTypeCodes', value); // Manually set the radio value
                        if (hasAttemptRef.current) {
                          FormHelper.fetchHasError(form);
                        }
                        handleSubmit(); // Automatically submit after selection
                      }}
                    >
                      Select Gift
                    </Button>
                  </div>
                </div>
              </Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    )
  }

  return (
    <div className="gift">
      <Form
        form={form}
        className="multiple-input-form"
        layout="vertical"
        initialValues={{
          giftActivationDate: dayjs(),
          ...(currentUser?.firstName && currentUser?.lastName && {
            giftSenderName: `${currentUser.firstName} ${currentUser.lastName}`,
            ...(currentUser?.email && {
              giftSenderEmail: currentUser.email
            })
          })
        }}
      >
        {defaultFields.map((group) => (
          <div key={`group-${group.group}`} className="field-group">
            {group.fields.map((field) => (
              <div 
                key={`field-${field.name}`}
                className="field-container selector-container"
              >
                <Item 
                  name={field.name}
                  className="single-select-item"
                  rules={rules[field.name]}
                  validateTrigger='onSubmit'
                >
                  {renderField(field)}
                </Item>
              </div>
            ))}
          </div>
        ))}

        <FlowStepFooter 
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          buttonText="Continue"
        />
      </Form>
    </div>
  )
}