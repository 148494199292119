import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Button, Empty, Typography } from 'antd';
import { CalendarOutlined, ArrowRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { BlogData } from '../../data/blog.data';
import { UserContext } from '../../contexts/user.context';
import { SupportSidebar } from '../supportSidebar/supportSidebar.component';
import { TestCatalog } from '../testCatalog/testCatalog.component';
import { listAppointments } from "../../services/appointment.service";
import { listConsults } from '../../services/consult.service';
import AppointmentStatus  from '../../enums/appointmentStatus.enum';
import dayjs from 'dayjs';
import './dashboard.scss';
import { ReactComponent as DoctorIcon } from '../../assets/svg/doctor-2.svg';
import ScheduleMenu from '../scheduleMenu/scheduleMenu.component';
import { listProductTypes } from '../../services/productType.service';
import ProductTypeCode from "../../enums/productTypeCode.enum.js";
import FlowType  from '../../enums/flowType.enum';
import MembershipHelper  from '../../helpers/membership.helper';
import MembershipTypeCode  from '../../enums/membershipTypeCode.enum';

const { Title, Paragraph, Text } = Typography;

const appointmentSelect = '_id status location start type products facility';
const appointmentPopulate = [{
  path: 'products',
  populate: [{
    path: 'productType'
  }]
}, {
  path: 'facility'
}];

const consultSelect = '_id name start status remoteLocation reason';
const consultPopulate = [{
  path: 'provider',
  select: 'firstName lastName photo npi'
}, {
  path: 'flow',
  select: '_id products type',
  populate: {
    path: 'products',
    select: 'productType',
    populate: {
      path: 'productType',
      select: 'code title',
    }
  }
}];

export const Dashboard = () => {
  const { currentUser, memberships } = useContext(UserContext);
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const [consults, setConsults] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    document.title = `Home | Instalab`;
    fetchAppointments();
    fetchConsults();
    fetchProductTypes();
  }, []);

  const fetchAppointments = async () => {
    try {
      const appts = await listAppointments({ 
        filter: {
          status: {
            $in: [AppointmentStatus.CONFIRMED]  // Only show upcoming appointments
          }
        },
        select: appointmentSelect,
        populate: appointmentPopulate,
        sort: '-start',
        limit: 3  // Limit to 3 most recent appointments for dashboard
      });
      setAppointments(appts);
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
    }
  };

  const fetchConsults = async () => {
    try {
      const fetchedConsults = await listConsults({
        select: consultSelect,
        populate: consultPopulate,
        sort: '-start',
        filter: {
          start: { $gt: new Date() }  // Only fetch future consults
        }
      });
      setConsults(fetchedConsults);
    } catch (error) {
      console.error('Failed to fetch consults:', error);
    }
  };

  const fetchProductTypes = async () => {
    if (!currentUser) return;

    let filter = {
      code: { $in: [
        ProductTypeCode.LONGEVITY_PANEL, 
        ProductTypeCode.CTCALCIUM,
        ProductTypeCode.CLEERLY,
        ProductTypeCode.DEXA_BODYCOMP,
        ProductTypeCode.DEXA_BONE,
        ProductTypeCode.PRENUVO,
        ProductTypeCode.VO2MAX
      ] }
    };

    try {
      let types = await listProductTypes({ filter });

      // Sort the test types
      types = types.sort((a, b) => {
        if (a.code === ProductTypeCode.ATHLETE_PANEL) return -1;
        if (b.code === ProductTypeCode.ATHLETE_PANEL) return 1;
        if (a.code === ProductTypeCode.LONGEVITY_PANEL) return -1;
        if (b.code === ProductTypeCode.LONGEVITY_PANEL) return 1;
        return a.title.localeCompare(b.title);
      });

      // Add athlete panel at the beginning if user is an athlete
      if (currentUser?.isAthlete) {
        types.unshift({
          code: ProductTypeCode.ATHLETE_PANEL,
          title: 'Athlete Panel'
        });
      }

      // Add consult options
      let consultTypes = [];

      // Add Heart Health Consult if user has active heart membership
      const heartHealthMembership = MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.HEART_HEALTH);
      if (heartHealthMembership) {
        consultTypes.push({
          title: 'Heart Health Consult',
          code: ProductTypeCode.CONSULT_HEART_HEALTH,
          flowType: FlowType.HEART_MEMBER_CONSULT
        });
      }

      // Add appropriate consult based on user type
      if (currentUser?.isAthlete) {
        consultTypes.push({
          title: 'Athlete Consult',
          code: ProductTypeCode.CONSULT_HEART_HEALTH,
          flowType: FlowType.ATHLETE_CONSULT
        });
      } else {
        consultTypes.push({
          title: 'Longevity Consult',
          code: ProductTypeCode.CONSULT_LONGEVITY,
          flowType: FlowType.LONGEVITY_CONSULT,
        });
      }
      
      // Add a divider object between tests and consults
      setProductTypes([
        ...types, 
        { isDivider: true },
        ...consultTypes
      ]);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const getLatestResearch = () => {
    return BlogData.slice(0, 4);
  };

  const ResearchCard = ({ title, description, readTime, publishedAt, onClick }) => (
    <Card 
      hoverable 
      className="research-card"
      onClick={onClick}
    >
      <Title level={4}>{title}</Title>
      <Paragraph className="card-description">{description}</Paragraph>
      <div className="card-footer">
        <Text className="read-time">{readTime}</Text>
        <Text className="dot-separator">•</Text>
        <Text className="published-date">{publishedAt}</Text>
        <ArrowRightOutlined className="arrow-icon" />
      </div>
    </Card>
  );

  const AppointmentCard = ({ appointment }) => (
    <Card 
      className="appointment-card"
      hoverable
      bordered={true}
    >
      <div className="appointment-card-content">
        <Row justify="space-between" align="top">
          <Col flex="1">
            <div className="tests">
              {appointment.products?.map(product => product.productType?.title).join(', ')}
            </div>

            <div className="time">
              <CalendarOutlined /> 
              {appointment.start ? 
                dayjs(appointment.start)
                  .tz(appointment.facility?.location?.timeZoneId || appointment.location?.timeZoneId)
                  .format('MMM D, YYYY @ h:mm a') 
                : 'TBD'
              }
            </div>

            <div className="location">
              <EnvironmentOutlined />
              <span>
                {appointment.facility ? 
                  `${appointment.facility.name}` :
                  `${appointment.location?.city}, ${appointment.location?.state}`
                }
              </span>
            </div>
          </Col>
          <Col>
            <Button 
              type="default"
              onClick={() => navigate('/patient/appointments')}
            >
              View Details
            </Button>
          </Col>
        </Row>
      </div>
    </Card>
  );

  return currentUser && (
    <div className="dashboard">
      <Row gutter={[24, 24]}>
        {/* Welcome Section */}
        <Col span={24}>
          <Card className="welcome-card  flat">
            <Title level={2}>
              {currentUser.firstName ? 
                `Welcome back, ${currentUser.firstName}.` : 
                'Welcome back!'}
            </Title>
            <Paragraph>Let us know what you'd like to do next.</Paragraph>
          </Card>
        </Col>

        {/* Main Content Area */}
        <Col xs={24} lg={16}>
          {/* Appointments Section */}
          <Card className="section-card flat">
            <div className="section-header">
              <Title level={3}>Upcoming Appointments</Title>
            </div>
            {(appointments.length > 0 || consults.length > 0) ? (
              <Row gutter={[24, 24]}>
                {appointments.map((appointment) => (
                  <Col xs={24} key={appointment._id}>
                    <AppointmentCard appointment={appointment} />
                  </Col>
                ))}
                
                {consults.map((consult) => (
                  <Col xs={24} key={consult._id}>
                    <Card 
                      className="appointment-card"
                      bordered={false}
                    >
                      <div className="appointment-card-content">
                        <Row justify="space-between" align="top">
                          <Col flex="1">
                            <div className="tests">
                              {consult.flow?.products?.[0]?.productType?.title || 'Consultation'}
                            </div>

                            <div className="time">
                              <CalendarOutlined /> 
                              {consult.start ? 
                                dayjs(consult.start)
                                  .tz(currentUser?.location?.timeZoneId || 'UTC')
                                  .format('MMM D, YYYY @ h:mm a') 
                                : 'TBD'
                              }
                            </div>

                            <div className="provider">
                              <DoctorIcon style={{ marginRight: 8, width: 16, height: 16 }} />
                              <span>
                                {consult.provider ? 
                                  `Dr. ${consult.provider.firstName} ${consult.provider.lastName}` :
                                  'Provider TBD'
                                }
                              </span>
                            </div>
                          </Col>
                          <Col>
                            <Button 
                              type="default"
                              onClick={() => navigate('/patient/consults')}
                            >
                              View Details
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="compact-empty"
                description={
                  <div className="empty-appointments">
                    <Text>No upcoming appointments</Text>
                    <ScheduleMenu
                      productTypes={productTypes}
                      currentUser={currentUser}
                    >
                      <Button 
                        type="primary" 
                        size="middle"
                        icon={<CalendarOutlined />}
                      >
                        Schedule Appointment
                      </Button>
                    </ScheduleMenu>
                  </div>
                }
              />
            )}
          </Card>

          {/* Test Catalog Section */}
          <Card className="section-card flat" style={{ marginTop: 24 }}>
            <div className="section-header">
              <Title level={3}>Popular Tests</Title>
              <Button type="link" className="link" onClick={() => navigate('/catalog')}>
                View All <ArrowRightOutlined />
              </Button>
            </div>
            <TestCatalog 
              showAll={false}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={12}
              viewMore={false}
              hideCategories={true}
              count={4}
              noFrills={true}
            />
          </Card>

          {/* Latest Research Section */}
          <Card className="section-card flat" style={{ marginTop: 24 }}>
            <div className="section-header">
              <Title level={3}>Latest Research You Can Use</Title>
              <Button type="link" className="link" onClick={() => navigate('/research')}>
                View All <ArrowRightOutlined />
              </Button>
            </div>
            <Row gutter={[16, 16]}>
              {getLatestResearch().map(({ id, title, description, publishedAt, readTime }) => (
                <Col xs={24} md={12} lg={12} xl={12} key={id}>
                  <ResearchCard
                    title={title}
                    description={description}
                    readTime={readTime}
                    publishedAt={publishedAt}
                    onClick={() => navigate(`/research/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>

        {/* Support Sidebar */}
        <Col 
          xs={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <SupportSidebar />
        </Col>
      </Row>
    </div>
  );
};