import { useContext } from "react";
import { Typography, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";
import ReactAudioPlayer from 'react-audio-player';
import { UserContext } from "../../../contexts/user.context";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import BlogId from "../../../enums/blogId.enum";
import { BlogData } from "../../../data/blog.data";

const { Paragraph } = Typography;

export const ApoBPost = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "apob-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Could your cholesterol test be missing a key risk factor? Meet ApoB.
        </Paragraph>

        <ReactAudioPlayer
          src="/audio/apob.mp3"
          controls
          className="blog-audio"
          onPlay={() => {
            PosthogHelper.track(currentUser, `[research] play audio: ${blogId}`, true)
          }}
        />

        <Paragraph className="blog-subheading">
          Most of us know to keep an eye on our LDL cholesterol, but new research suggests that ApoB—a lesser-known marker—might give us a clearer picture of our heart health.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          What Is ApoB?
        </Paragraph>
        <Paragraph className="blog-description">
          Essentially, ApoB shows how many cholesterol-carrying particles are in your bloodstream. Think of LDL cholesterol as cargo and ApoB as the delivery trucks that transport it. Each ApoB particle represents one of these “trucks,” no matter how much cholesterol it's carrying.
        </Paragraph>
        <Paragraph className="blog-description">
          Just like trucks create traffic, too many ApoB particles signal congestion and higher risk—risks that your standard cholesterol test might miss.
        </Paragraph>

        <Paragraph className="blog-title">
          How Does ApoB Impact Your Health?
        </Paragraph>
        <Paragraph className="blog-description">
          Knowing your ApoB levels is a powerful predictor of heart disease, demonstrated by a <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/22906895/', '_blank')} className="blog-link">2012 meta-analysis</a> of over 131,000 people:
        </Paragraph>

        <Table
          className="blog-table"
          dataSource={[{
            reduction: '10 mg/dL decrease',
            benefit: '9% reduction in heart disease'
          }, {
            reduction: '10 mg/dL decrease',
            benefit: '6% reduction in overall cardiovascular events'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column 
            title="Reduction in ApoB" 
            dataIndex="reduction"
          />
          <Table.Column 
            title="Associated Health Benefit" 
            dataIndex="benefit"
          />
        </Table>

        <Paragraph className="blog-description">
          The <a onClick={() => window.open('https://doi.org/10.1016/j.jacc.2015.10.055', '_blank')} className="blog-link"><i>CARDIA study</i></a> further showed ApoB is especially important for young adults. Over a 25-year period, young adults with high ApoB levels were found to be <strong>2.28 times</strong> more likely to develop coronary artery calcification (CAC)—the buildup of plaque in your coronary arteries. So, even if you're young, knowing your ApoB levels might reveal hidden future risks.
        </Paragraph>

        <Paragraph className="blog-title">
          How to Get ApoB Tested
        </Paragraph>
        <Paragraph className="blog-description">
          Despite its strong predictive value, ApoB testing is not yet routine in traditional healthcare settings, largely because:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Testing Standards Lag Behind Research:</strong> It takes time for healthcare practices to catch up with new findings, and LDL has been the primary focus for years.
          </li>
          <li>
            <strong>Insurance Coverage:</strong> ApoB testing isn't always covered by insurance.
          </li>
        </ul>
        <Paragraph className="blog-description">
          If your doctor doesn't offer ApoB testing, Instalab makes it easy. We can <a onClick={() => {
            navigate('/heart-health-test')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: request test`, true)
          }} className="blog-link">send a blood test kit</a> to your home or <a onClick={() => {
            navigate('/longevity-panel')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: longevity panel`, true)
          }} className="blog-link">arrange an at-home blood draw</a> by a phlebotomist.
        </Paragraph>

        <Paragraph className="blog-title">
          Lifestyle Changes and Treatments
        </Paragraph>
        <Paragraph className="blog-description">
          If you find that your ApoB levels are high, the good news is that there are many lifestyle changes and treatments can help bring them down:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Diet:</strong> Limit saturated fats, trans fats, and processed sugars. Eat more whole grains, fruits, vegetables, lean proteins and fiber-rich foods.
          </li>
          <li>
            <strong>Exercise:</strong> At least 150 minutes of exercise per week of physical activity.
          </li>
          <li>
            <strong>Weight Management:</strong> Maintain a healthy weight, especially by reducing visceral fat.
          </li>
          <li>
            <strong>Supplements:</strong> <a onClick={() => navigate(`/research/${BlogId.SUPPLEMENT}`)} className="blog-link"><i>Berberine</i> and <i>Red Yeast Rice</i></a> have been shown to reduce ApoB levels, sometimes achieving results that rival those of prescribed medications.
          </li>
          <li>
            <strong>Medications:</strong> Statins, ezetimibe, or <a onClick={() => navigate(`/research/${BlogId.PCSK9I}`)} className="blog-link">PCSK9 inhibitors</a> lower ApoB levels by reducing cholesterol production or improving its clearance from the bloodstream.
          </li>
        </ul>

        <Paragraph className="blog-title">
          Reach Optimal ApoB Levels with Instalab
        </Paragraph>
        <Paragraph className="blog-description">
          For those who want a more structured approach to reducing ApoB, Instalab offers a <a onClick={() => {
            navigate('/heart')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: heart health program`, true)
          }} className="blog-link">Heart Health Program</a> to support you:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Personalized Coaching:</strong> Your Instalab physician will create a tailored plan that may include dietary recommendations, exercise guidance, supplements, or prescribed medications.
          </li>
          <li>
            <strong>Regular Testing:</strong> Every six weeks, we retest your ApoB levels to ensure measurable progress and make adjustments as needed.
          </li>
          <li>
            <strong>Proven Results:</strong> Most patients reach their target ApoB levels within six months.
          </li>
        </ul>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Robinson, J. G., Wang, S., & Jacobson, T. A. (2012). Meta-analysis of the comparison of effectiveness of lowering apolipoprotein B versus low-density lipoprotein cholesterol and nonhigh-density lipoprotein cholesterol for cardiovascular risk reduction in randomized trials. American Journal of Cardiology, 110(10), 1468-1476. <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/22906895/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/22906895/</a>
            </li>
            <li>
              Wilkins, J. T., Li, R. C., Sniderman, A., Chan, C., & Lloyd-Jones, D. M. (2016). Discordance between apolipoprotein B and LDL-cholesterol in young adults predicts coronary artery calcification: The CARDIA Study. Journal of the American College of Cardiology, 67(2), 193-201. <a onClick={() => window.open('https://doi.org/10.1016/j.jacc.2015.10.055', '_blank')}>https://doi.org/10.1016/j.jacc.2015.10.055</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};