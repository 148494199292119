import { useEffect, useRef, useState } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ConfirmGift } from "./confirmGift.component"
import { completeFlow } from "../../services/flow.service.js"
import { listProductTypes } from '../../services/productType.service'
import dayjs from "dayjs"

export const Step = {
  SELECT: 'select',
  DETAILS: 'details',
  PAY_LONGEVITY_PANEL: 'pay-longevity-panel',
  PAY_LONGEVITY_PANEL_MOBILE_DRAW: 'pay-longevity-panel-mobile-draw',
  PAY_GALLERI: 'pay-galleri',
  PAY_TESTOSTERONE_PANEL: 'pay-testosterone-panel',
  PAY_FERTILITY_PANEL: 'pay-fertility-panel',
  PAY_HEART_HEALTH_PANEL: 'pay-heart-health-panel',
  CONFIRM: 'confirm',
}

export const GiftFlow = () => {
  const [flow, setFlow] = useState()
  const [hasPass, setHasPass] = useState()
  const [initialUser, setInitialUser] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const hasCompletedFlowRef = useRef(false)

  useEffect(() => {
    fetchProductTypes()
  }, [])

  // Add this useEffect to reset the ref when flow changes
  useEffect(() => {
    hasCompletedFlowRef.current = false;
    return () => { 
      fetchProductTypes();
      hasCompletedFlowRef.current = false; 
    }; // Reset on unmount
    
 }, [flow?._id]);

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.GIFT_GALLERI,
            ProductTypeCode.GIFT_LONGEVITY_PANEL,
            ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW,
            ProductTypeCode.GIFT_TESTOSTERONE_PANEL,
            ProductTypeCode.GIFT_FERTILITY_PANEL,
            ProductTypeCode.GIFT_HEART_HEALTH_PANEL
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType?.code === code)
  }

  useEffect(() => {
    fetchSkeleton()
  }, [flow, hasPass, initialUser, productTypes])


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({

      [Step.SELECT]: {
        type: StepType.GIFT_SELECT,
        title: `Choose your gift`,
        description: `Select a test to gift. You can personalize your gift message in the next step.`,
        buildFlow: true,
        multiple: false,
        nextStep: Step.DETAILS,
      },
      [Step.DETAILS]: {
        type: StepType.GIFT_DETAILS,
        title: 'Personalize your gift',
        description: 'Your gift will be emailed instantly or on the date of your choosing.',
        buildFlow: true,
        multiple: false,
        onNextStep: (patient, flow) => {
          if (!flow?.giftProductTypeCodes?.length) return;

          switch (flow?.giftProductTypeCodes[0]) {
            case ProductTypeCode.GIFT_GALLERI:
              return Step.PAY_GALLERI
            case ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW:
              return Step.PAY_LONGEVITY_PANEL_MOBILE_DRAW
            case ProductTypeCode.GIFT_TESTOSTERONE_PANEL:
              return Step.PAY_TESTOSTERONE_PANEL
            case ProductTypeCode.GIFT_FERTILITY_PANEL:
              return Step.PAY_FERTILITY_PANEL
            case ProductTypeCode.GIFT_HEART_HEALTH_PANEL:
              return Step.PAY_HEART_HEALTH_PANEL
            case ProductTypeCode.GIFT_LONGEVITY_PANEL:
            default:
              return Step.PAY_LONGEVITY_PANEL
        
          }

        },  
        isLocked: () => {
          return flow?.products?.length
        }
      },
      [Step.PAY_LONGEVITY_PANEL]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.code,
        nextStep: Step.CONFIRM,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.cost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.title}.</>,

      },
      [Step.PAY_LONGEVITY_PANEL_MOBILE_DRAW]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.code,
        nextStep: Step.CONFIRM,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.cost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.title}.</>,
      },
      [Step.PAY_GALLERI]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_GALLERI)?.code,
        nextStep: Step.CONFIRM,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.GIFT_GALLERI)?.cost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_GALLERI)?.title}.</>,
      },
      [Step.PAY_TESTOSTERONE_PANEL]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.code,
        nextStep: Step.CONFIRM,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.cost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.title}.</>,
      },
      [Step.PAY_FERTILITY_PANEL]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.code,
        nextStep: Step.CONFIRM,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.cost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.title}.</>,
      },
      [Step.PAY_HEART_HEALTH_PANEL]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.code,
        nextStep: Step.CONFIRM,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.cost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.title}.</>,
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>You're all set! 🎉</>,
        description: <>Your gift has been scheduled and will be emailed {
          dayjs(flow?.giftActivationDate).isSame(dayjs(), 'day') 
            ? 'in a few minutes' 
            : `on ${dayjs(flow?.giftActivationDate).format('MMMM D, YYYY')}`
        }.<br/><br/>Thank you for choosing the gift of health – it's one of the most thoughtful and impactful gifts you can give!</>,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmGift />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            try {
               hasCompletedFlowRef.current = true;
               await completeFlow(flow?._id);
            } catch (error) {
               console.error("Error completing flow:", error);
            }
         }
        }
      },
    })
  }
  return (
     <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      setHasPass={setHasPass}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      productTypeCode={flow?.giftProductTypeCodes?.[0] }
      startIndex={1}
    />
  )
}