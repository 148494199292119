import React, { useEffect, useState } from 'react';
import { Avatar, Space, Card, Typography } from 'antd'
import './supportSidebar.scss';
import { UserOutlined } from '@ant-design/icons'
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Breakpoint, FlowType, Role } from "../../enums/index.enum";
import { addSlack } from "../../services/slack.service"
import FrontChatHelper from '../../helpers/fronchat.helper';
import MembershipHelper from '../../helpers/membership.helper';
import MembershipTypeCode from "../../enums/membershipTypeCode.enum";

const { Title } = Typography;

// Define sub-components first
const ConciergeComponent = ({ instalabMembership, onChat, onBecomeMember }) => {
  return (
    <Card className="support-card" bordered={false}>
      {!instalabMembership && (
        <div className="members-only-message" onClick={onBecomeMember}>
          Members-only feature. Learn more.
        </div>
      )}

      <Title level={5}>Member Concierge</Title>
      <div className={`support-content ${!instalabMembership ? 'blur-effect' : ''}`}>
        <div className="support-description">
          <a onClick={onChat} className="secondary-link">Message us</a> with any health-related questions or for help with your account.
        </div>
      </div>
    </Card>
  );
};

const PhysicianComponent = ({ currentUser, heartMembership, onBookAthleteSession, onBookSession, onBookHHSession }) => {
  return (
    <>
      <Card className="support-card" bordered={false}>
        <Title level={5}>{currentUser.isAthlete ? 'Athlete Physician' : 'Longevity Physician'}</Title>
        <div className="support-description">
          <a onClick={currentUser.isAthlete ? onBookAthleteSession : onBookSession} className="secondary-link">
            Schedule call
          </a> to discuss your long-term health with a {currentUser.isAthlete ? 'physician specializing in pro athletes' : 'longevity expert'}.
        </div>
      </Card>

      {heartMembership && (
        <Card className="support-card" bordered={false}>
          <Title level={5}>Heart Health Physician</Title>
          <div className="support-description">
            <a onClick={onBookHHSession} className="secondary-link">Schedule call</a> to discuss your heart health treatment.
          </div>
        </Card>
      )}
    </>
  );
};

export const SupportSidebar = () => {
  const navigate = useNavigate()
  const { currentUser, instalabMembership, memberships } = useContext(UserContext)
  const [heartMembership, setHeartMembership] = useState()

  useEffect(() => {
    document.title = `Home | Instalab`
  }, [])

  useEffect(() => {
    FrontChatHelper.identifyUser(currentUser);
  }, [currentUser]);

  const onChat = () => {
    window.FrontChat("show");
  };

  const onIntroCall = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/d/4kj-7q2-bnb?hide_gdpr_banner=1' });
  }

  const onBecomeMember = async() => {
    try {
      await addSlack({
      message: `${currentUser.firstName} ${currentUser.lastName}: Membership Upgrade Click - Support Sidebar`,
      channel: process.env.REACT_APP_PATIENT_SLACK
    });
    }
    catch (error) {
      console.error('Failed to log message to Slack:', error);
    }
    navigate("/patient/memberships")
  }

  const onBookSession = () => {
    navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
  }

  const onBookAthleteSession = () => {
    navigate(`/flow/${FlowType.ATHLETE_CONSULT}`)
  }

  const onBookHHSession = () => {
    navigate(`/flow/${FlowType.HEART_MEMBER_CONSULT}`)
  }

  const onReferral = () => {
    navigate(`/flow/${FlowType.REFERRAL}`)
  }

  useEffect(() => {
    setHeartMembership(getHeartMembership())
  }, [memberships])

  const getHeartMembership = () => {
    if (!memberships?.length) return null
    const heartHealthMembership = MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.HEART_HEALTH)
    if (heartHealthMembership) {
      return heartHealthMembership
    }
    return null
  }

  const FounderComponent = () => {
    return ( 
      <><div className="support-subtitle">Talk to our Founders</div>
      <div className="support-description"><a onClick={onIntroCall} className="secondary-link">Schedule a call</a> with our founders. They're eager to speak with you.</div></>
    )
  }

  const ReferralComponent = () => {
    return (<>                
      <div className="support-subtitle">Specialist Referral</div>
      <div className="support-description"><a onClick={onReferral} className="secondary-link">Request</a> a referral for anything that requires a specific type of specialist.</div> </>)
  }
    
  return currentUser && (
    <div className="support-sidebar">
      <Card className="section-card flat">
        <div className="section-header" style={{display:'block'}}>
          <Title level={3} style={{ marginBottom: 8 }}>Have a question?</Title>
          <div className="support-description">Our team is here to help.</div>
        </div>

        <div className="support-avatars">
          <Space wrap size={13}>
            <Avatar 
              className="support-avatar" 
              src="/img/steven-square.png" 
              size={55} 
              icon={<UserOutlined />} 
            />
            <Avatar 
              className="support-avatar" 
              src="/img/adora-square.png" 
              size={55} 
              icon={<UserOutlined />} 
            />
            <Avatar 
              className="support-avatar" 
              src="/img/dan-square.png" 
              size={55} 
              icon={<UserOutlined />} 
            />
          </Space>
        </div>

        {instalabMembership ? (
          <ConciergeComponent instalabMembership={instalabMembership} onChat={onChat} onBecomeMember={onBecomeMember} />
        ) : (
          <Card className="support-card" bordered={false}>
            <Title level={5}>Message Support</Title>
            <div className="support-description">
              <a onClick={onChat} className="secondary-link">Ask a question</a> about any of Instalab's products and services or your account.
            </div>
          </Card>
        )}

        {currentUser.role === Role.PROVIDER ? (
          <Card className="support-card" bordered={false}>
            <FounderComponent />
          </Card>
        ) : (
          <>
            <PhysicianComponent currentUser={currentUser} heartMembership={heartMembership} onBookAthleteSession={onBookAthleteSession} onBookSession={onBookSession} onBookHHSession={onBookHHSession} />
            {/* <ReferralComponent/> */}
          </>
        )}
      </Card>
    </div>
  );
}