const BlogId = Object.freeze({
  OVEREXERCISE: 'overexercise',
  FORECAST: 'forecast',
  SOFT_PLAQUE: 'soft-plaque',
  INSURANCE: 'insurance',
  CAC: 'cac',
  CUMULATIVE_EXPOSURE: 'cumulative-exposure',
  GALLERI: 'galleri',
  PEAK: 'peak',
  SAUNA: 'sauna',
  FOUNDER: 'founder',
  PCSK9I: 'pcsk9i',
  APOB: 'apob',
  SUPPLEMENT: 'supplement',
  BLOOD_PRESSURE_DIET: 'blood-pressure-diet',
})

export default BlogId