import React, { useEffect, useState } from 'react';
import { Table, Button, Tooltip, Card, Typography } from 'antd'
import { listPrescriptions } from "../../services/prescription.service"
import moment from 'moment'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { useNavigate } from "react-router-dom";
import { TrackingHelper } from "../../helpers/tracking.helper.js"

import "./prescriptions.scss"
import PrescriptionStatus from '../../enums/prescriptionStatus.enum.js';

const { Column } = Table
const { Text } = Typography

export const Prescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPrescriptions = async () => {
      setPrescriptions(await listPrescriptions())
      setIsLoading(false)
    }
    document.title = 'Prescriptions | Instalab'
    fetchPrescriptions()
  }, [])


  const onGetTreatment = async () => {
    navigate('/heart')    
  };

  const renderMobileCard = (prescription) => {
    const { contents, createdAt, tracking, status, isPickupOrder, _id, hasRefill, daysToRefill } = prescription;
    
    return (
      <Card 
        key={_id} 
        className="prescription-card"
      >
        <div className="prescription-card-content">
          <div className="prescription-card-row">
            <Text className="medication-name">
              {contents?.type}
            </Text>
          </div>
          
          <div className="prescription-card-row">
            {contents?.units} units
          </div>

          <div className="prescription-card-row">
            {contents?.sig}
          </div>

          <div className="prescription-card-row">
            Ordered: {moment(createdAt).format('MM/DD/YYYY')}
          </div>

          {/* Tracking section */}
          <div className="prescription-card-row">
            {isPickupOrder ? (
              'n/a'
            ) : tracking?.trackingEvents?.length > 0 ? (
              <div>
                Tracking: <a className="link" onClick={(e) => {
                  e.stopPropagation();
                  window.open(`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking.trackingNumber}`, '_blank');
                }}>{tracking.trackingNumber}</a>
              </div>
            ) : tracking ? (
              <div>
                Tracking: <a className="link" href={TrackingHelper.getDeliveryService(tracking.trackingNumber).url} target="_blank">{tracking.trackingNumber}</a>
              </div>
            ) : (
              <div>
                Status: {status === PrescriptionStatus.SENT ? 'Pharmacy Fulfilling' : 'Pending Physician Approval'}
              </div>
            )}
          </div>

          {/* Next Refill section */}
          <div className="prescription-card-row">
            Next Refill: {
              status === PrescriptionStatus.PAUSED ? 'Paused' :
              !hasRefill ? 'None' :
              daysToRefill ? moment(createdAt).add(daysToRefill, 'days').format('MM/DD/YYYY') :
              contents ? moment(createdAt).add(contents.units/(contents.doseQty ? contents.doseQty : 1), 'days').format('MM/DD/YYYY') :
              moment(createdAt).add(90, 'days').format('MM/DD/YYYY')
            }
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div className="prescriptions">
      <PageHeader
        title='Prescriptions'
        actions={(<Button type='primary' onClick={onGetTreatment}>+ Get Treatment</Button>)}  
      />

      <div className="prescriptions-container">
        {/* Desktop View */}
        <div className="desktop-view">
          <Table
            loading={isLoading}
            size='small'
            className='primary-table'
            pagination={false}
            rowKey='_id'
            dataSource={prescriptions}
            scroll={{ x: 'max-content' }}
          >

            <Column
              title='Medication'
              dataIndex='medication'
              render={(_, { contents })=>contents?.type}
              fixed="left"

            />

          <Column
              title='Units'
              dataIndex='units'
              render={(_, { contents })=>contents?.units}
            />

            <Column
              title='Directions'
              dataIndex='directions'
              render={(_, { contents })=>contents?.sig}
            />

            <Column
              title='Ordered'
              dataIndex='createdAt'
              render={createdAt => moment(createdAt).format('MM/DD/YYYY')}
            />

            <Column
              title='Tracking'
              dataIndex='status'
              render={(status, { tracking, _id, isPickupOrder }) => {
                if (isPickupOrder) 
                  return 'n/a'

                if (tracking?.trackingEvents?.length > 0) {
                  return <Tooltip 
                  overlayStyle={{ maxWidth: '390px'}}
                  title={(
                    <ul className="tracking-list">
                      {tracking.trackingEvents.map(({ eventTimestamp, eventType }, index) => (
                        <li key={`tracking-${_id}-${index}`}>
                          <strong>{moment(eventTimestamp).format('MM/DD/YYYY')}</strong>: {eventType}
                        </li>
                      ))}
                    </ul>
                  )}
                >
                  <a className="link" onClick={(e) => {
                    e.stopPropagation(); 
                    window.open(`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking.trackingNumber}`, '_blank');
                  }}>{tracking.trackingNumber}</a>
                </Tooltip>
              }
              else if (tracking) {
                return <a className="link" href={TrackingHelper.getDeliveryService(tracking.trackingNumber).url} target="_blank">{tracking.trackingNumber}</a>
              
                } else if (status === PrescriptionStatus.SENT) {
                  return 'Pharmacy Fulfilling'
                } else {
                  return 'Pending Physician Approval'
                }
              }}
            />

          <Column
              title='Next Refill'
              dataIndex='createdAt'
              render={(createdAt, { contents, status, hasRefill, daysToRefill }) => { 
                if (status === PrescriptionStatus.PAUSED) {
                  return 'Paused'
                } 
                else if (!hasRefill) {
                  return 'None'
                }
                else if (daysToRefill) {
                  return moment(createdAt).add(daysToRefill, 'days').format('MM/DD/YYYY')
                }
                else {
                  return contents ? moment(createdAt).add(contents.units/(contents.doseQty ? contents.doseQty : 1), 'days').format('MM/DD/YYYY') :  moment(createdAt).add(90, 'days').format('MM/DD/YYYY') 
                }
              }}
            />


          </Table>
        </div>

        {/* Mobile View */}
        <div className="mobile-view">
          {isLoading ? (
            <div className="loading-container">Loading...</div>
          ) : (
            prescriptions.map(renderMobileCard)
          )}
        </div>
      </div>
    </div>
  )
}