const ProductTypeCode = Object.freeze({
  ELITE_PANEL: 'elite panel',
  LONGEVITY_PANEL: 'longevity panel',  
  ATHLETE_PANEL: 'athlete panel',
  TEST_KIT: 'test kit',
  LAB_VISIT: 'lab visit',
  ALLERGY_PANEL: 'allergy panel',
  CUSTOM_PANEL: 'custom panel',
  CONSULT_LONGEVITY: 'consult - longevity',
  MOBILE_BLOOD_DRAW: 'mobile blood draw',
  CONSULT_ATHLETE: 'consult - athlete',
  CONSULT_HEART_HEALTH: 'consult - heart health',

  // Testing
  HEART_BASELINE: 'heart heatlh baseline',
  GALLERI: 'galleri test',
  PRENUVO: 'prenuvo',
  CTCALCIUM: 'ct calcium scan',
  DEXA_BODYCOMP: 'dexa - body composition',
  DEXA_BONE: 'dexa - bone density',
  VO2MAX: 'vo2max',
  FERTILITY: 'fertility',
  CLEERLY: 'cleerly',

  // At-home self collection tests
  HEART_HEALTH_TEST_KIT: 'heart health - test kit',
  HEART_HEALTH_GENETIC_TEST: 'heart health genetic test',
  CHOLESTEROL_TREATMENT_TEST_KIT: 'cholesterol treatment - test kit',
  FIT_STOOL: 'fit stool test',
  LONGEVITY_GENETIC_TEST: 'longevity genetic test',
  CGM: 'cgm',
  BP_MONITOR: 'bp monitor',
  TESTOSTERONE_PANEL_KIT: 'testosterone panel - test kit',
  MYCOTOX_PROFILE: 'mycotox profile',
  WATCHPAT_ONE: 'watchpat one',
  BIOBEAT: 'biobeat',
  BIOBEAT_BP_MONITOR: 'biobeat + bp monitor',
  TRUAGE_COMPLETE: 'truage - complete',
  TOXIC_METALS_URINE_TEST: 'toxic metals - urine test',
  DIURNAL_CORTISOL_PROFILE: 'diurnal cortisol profile',
  TOTAL_TESTOSTERONE_KIT: 'total testosterone - test kit',

  // blood draw add ons 
  APOE_ADDON: 'apoe',
  CORTISOL: 'cortisol',
  CPEPTIDE: 'c-peptide',
  FRUCTOSAMINE: 'fructosamine',
  IODINE: 'iodine',
  NMR_LIPOPROFILE: 'nmr lipoprofile',
  OMEGA_3_ADDON: 'omega 3',
  OMEGACHECK: 'omegacheck',
  SELENIUM: 'selenium',
  TSH: 'tsh',
  THYROID_PANEL: 'thyroid panel',
  HEAVY_METALS: 'heavy metals',
  ANA_SCREEN: 'ana screen',
  BIOMETRICS: 'biometrics',
  IRON_PANEL: 'iron panel',
  IGF1: 'igf-1',
  DHEAS: 'dhea-s',
  IL6: 'il-6',
 
  // SUPPLEMENTS
  SUPPLEMENT_PACK: 'supplement pack',

  // TREATMENTS
  LEQVIO: 'leqvio',

  // GIFTS
  GIFT_LONGEVITY_PANEL: 'gift - longevity panel',
  GIFT_LONGEVITY_PANEL_MOBILE_DRAW: 'gift - longevity panel + mobile draw',
  GIFT_GALLERI: 'gift - gallieri',
  GIFT_TESTOSTERONE_PANEL: 'gift - testosterone panel',
  GIFT_FERTILITY_PANEL: 'gift - fertility panel',
  GIFT_CORE_MEMBERSHIP: 'gift - core membership',
  GIFT_PREMIUM_MEMBERSHIP: 'gift - premium membership',
  GIFT_HEART_HEALTH_PANEL: 'gift - heart health panel',

  // MISC
  TEST_MATRIX: 'testing matrx',
  INSURANCE_COPAY: 'insurance copay',
  DEMO_PRODUCT: 'demo product',
  REFERRAL: 'referral',

  // INACTIVE
  CALCIFEDIOL: 'Calcifediol',
  VITAMIN_B12: 'Vitamin B12',
  VITAMIN_B9_B12: 'Vitamin B9 + B12',
  CONSULT_LONGEVITY_60: 'consult - longevity - 60 minutes',
  CONSULT_WEIGHT_MANAGEMENT: 'consult - weight management',
  CONSULT_METABOLIC_HEALTH: 'consult - metabolic health',
  CONSULT_METABOLIC_HEALTH_60: 'consult - metabolic health - 60 minutes',
  CONSULT_LIPIDOLOGIST: 'consult - lipidologist',
  OMEGAQUANT_COMPLETE: 'omegaquant - complete',
  OMEGAQUANT_BASIC: 'omegaquant - basic',
  DEXA_BODYCOMP_REFERRAL: 'dexa - body composition - referral',
  DEXA_BONE_REFERRAL: 'dexa - bone density - referral',
  CTCALCIUM_REFERRAL: 'ct calcium - referral',
  HEART_HEALTH_LAB_VISIT: 'heart health - lab visit', 
  TOXIC_HAIR_TEST: 'toxic hair test',
  TRUAGE_PACE: 'truage - pace',
  DEXA_BOTH: 'dexa - body composition + bone density',
  LONGEVITY_PANEL_PSC: 'longevity panel psc',

})

export default ProductTypeCode;