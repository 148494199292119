import { Table, Typography } from "antd";
import classNames from "classnames";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import Color from '../../../colors.scss';
import { BlogData } from "../../../data/blog.data";
import { useParams } from "react-router-dom";
const { Paragraph } = Typography

export const SaunaPost = () => {
  const { blogId } = useParams()
  return (
    <div className={classNames("blog-post", "sauna-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Will a sauna make you live longer?
        </Paragraph>
        <Paragraph className="blog-subheading">
          Sure, your favorite podcaster loves saunas. Peter Attia, Andrew Huberman, Rhonda Patrick, take your pick. But if you trace their advice back to the original research papers, it all stems from two Finnish scientists: Tanjaniina Laukkanen (researcher) and Jari Laukkanen (professor, cardiologist).
          <br/><br/>
          The participants in their studies were predominantly middle-aged Finns (42-60 years old), hailing from a small Finnish town called Kuopio. As a culture, Finland is obsessed with saunas with around 60% of Finnish households having a sauna on-site. It's not surprising this is where most of the research originates.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          Just give me the best routine:
        </Paragraph>
        {/* Routine Table */}
        <Table
          className="blog-table"
          dataSource={[{
            parameter: 'Heat',
            value: '≥80°C (176°F)',
          }, {
            parameter: 'Time',
            value: '≥20 min',
          }, {
            parameter: 'Frequency',
            value: '≥4x per week',
          }, {
            parameter: 'Type',
            value: 'Dry Sauna',
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="Parameter" dataIndex="parameter" />
          <Table.Column title="Recommendation" dataIndex="value" />
        </Table>

        <Paragraph className="blog-title">
          Should I believe the research?
        </Paragraph>
        <Paragraph className="blog-description">
          If you're not a middle-aged Finnish man with lifelong exposure to saunas, this research may not directly apply to you. Moreover, all these studies are observational. So while there is a strong correlation between sauna use and longer life span, you can't say that sauna use causes you to live longer - as we all know, correlation does not prove causation.
        </Paragraph>
        <Paragraph className="blog-description">
          With that said, the evidence is still pretty damn convincing. Even if you come from a different ethnicity or culture, the data is so overwhelmingly positive that it likely applies to you too.
        </Paragraph>

        <Paragraph className="blog-title">
          How often should I sauna?
        </Paragraph>
        <Paragraph className="blog-description">
          Based on the research, we recommend getting in at least 4 sessions per week, which significantly reduces your risk of major causes of death, including heart disease, stroke, Alzheimer's, and dementia. These findings are impressive, especially since they're adjusted for confounders like age, sex, physical exercise, socio-economic status and more.
        </Paragraph>

        {/* Risk Reduction Table */}
        <Table
          className="blog-table"
          dataSource={[{
            event: 'Sudden Cardiac Death',
            moderate: 'Not significant',
            frequent: '-63%'
          }, {
            event: 'Fatal Coronary Heart Disease',
            moderate: '-23%',
            frequent: '-48%'
          }, {
            event: 'Fatal Cardiovascular Disease',
            moderate: '-27%',
            frequent: '-50%'
          }, {
            event: 'All-cause Mortality (aka Death)',
            moderate: '-24%',
            frequent: '-40%'
          }, {
            event: 'Dementia',
            moderate: 'Not significant',
            frequent: '-66%'
          }, {
            event: 'Alzheimer\'s Disease',
            moderate: 'Not significant',
            frequent: '-65%'
          }, {
            event: 'Hypertension',
            moderate: 'Not significant',
            frequent: '-47%'
          }, {
            event: 'Stroke',
            moderate: 'Not significant',
            frequent: '-62%'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="Event" dataIndex="event" />
          <Table.Column 
            title="Risk reduction @ 2-3x per week" 
            dataIndex="moderate" 
            className="blog-cell-custom"
            render={value => (
              <div 
                className="blog-cell-inner"
                style={{
                  backgroundColor: value === 'Not significant' ? 'rgba(255,255,255,0)' : Color.success_bg
                }}
              >
                {value}
              </div>
            )}
          />
          <Table.Column 
            title="Risk reduction @ 4-7x per week" 
            dataIndex="frequent" 
            className="blog-cell-custom"
            render={value => (
              <div 
                className="blog-cell-inner"
                style={{
                  backgroundColor: value === 'Not significant' ? 'rgba(255,255,255,0)' : Color.success_bg
                }}
              >
                {value}
              </div>
            )}
          />
        </Table>
        <Paragraph className="blog-description">
          * Note: the reference group used a dry sauna 1x per week.
        </Paragraph>
        
        <Paragraph className="blog-title">
          How long should each sauna session last?
        </Paragraph>
        <Paragraph className="blog-description">
          At least 20 minutes is a must. While sitting in a sauna this long may be difficult at first, you hit some insane benefits for your cardiovascular system once you pass the 20 minute threshold. If you have a family history of heart disease or stroke, it's especially critical for you to stick to this cutoff.
        </Paragraph>

        {/* Duration Table */}
        <Table
          className="blog-table"
          dataSource={[{
            event: 'Sudden Cardiac Death',
            moderate: 'Not significant',
            longer: '-52%'
          }, {
            event: 'Fatal Coronary Heart Disease',
            moderate: 'Not significant',
            longer: '-26%'
          }, {
            event: 'Fatal Cardiovascular Disease',
            moderate: 'Not significant',
            longer: '-14%'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="Event" dataIndex="event" />
          <Table.Column 
            title="Risk reduction @ 11-19 minutes" 
            dataIndex="moderate" 
            className="blog-cell-custom"
            render={value => (
              <div 
                className="blog-cell-inner"
                style={{
                  backgroundColor: value === 'Not significant' ? 'rgba(255,255,255,0)' : Color.success_bg
                }}
              >
                {value}
              </div>
            )}
          />
          <Table.Column 
            title="Risk reduction @ ≥20 minutes" 
            dataIndex="longer" 
            className="blog-cell-custom"
            render={value => (
              <div 
                className="blog-cell-inner"
                style={{
                  backgroundColor: value === 'Not significant' ? 'rgba(255,255,255,0)' : Color.success_bg
                }}
              >
                {value}
              </div>
            )}
          />
        </Table>
        <Paragraph className="blog-description">
          * Note: the reference group used a dry sauna for &lt;11 minutes per session.
        </Paragraph>

        <Paragraph className="blog-title">
          What kind of sauna should I use?
        </Paragraph>
        <Paragraph className="blog-description">
          These studies are based entirely on dry saunas at ~80°C (176°F). Research on alternatives like infrared saunas does exist, but the evidence is not as exhaustive or compelling. By comparison, dry saunas have a much deeper history, allowing for more in-depth and significant studies.
        </Paragraph>

        <Paragraph className="blog-title">
          Are saunas safe for everyone?
        </Paragraph>
        <Paragraph className="blog-description">
          No, you should not use a sauna if you're pregnant or prone to heat strokes. Make sure to drink plenty of water to avoid dehydration. Talk to your doctor (or Instalab longevity physician) before you start.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Laukkanen, T., Khan, H., Zaccardi, F., & Laukkanen, J. A. (2015). Association between sauna bathing and fatal cardiovascular and all-cause mortality events. JAMA Internal Medicine, 175(4), 542-548. <a onClick={() => window.open('https://doi.org/10.1001/jamainternmed.2014.8187', '_blank')}>https://doi.org/10.1001/jamainternmed.2014.8187</a>
            </li>
            <li>
              Laukkanen, T., Kunutsor, S. K., Kauhanen, J., & Laukkanen, J. A. (2016). Sauna bathing is inversely associated with dementia and Alzheimer's disease in middle-aged Finnish men. Age and Ageing, 0(1), 1-5. <a onClick={() => window.open('https://academic.oup.com/ageing/article/46/2/245/2654230', '_blank')}>https://doi.org/10.1093/ageing/afw212</a>
            </li>
            <li>
              Zaccardi, F., Laukkanen, T., Willeit, P., Kunutsor, S. K., Kauhanen, J., & Laukkanen, J. A. (2017). Sauna bathing and incident hypertension: A prospective cohort study. American Journal of Hypertension, 30(11), 1120–1125. <a onClick={() => window.open('https://academic.oup.com/ajh/article-abstract/30/11/1120/3867393?redirectedFrom=fulltext', '_blank')}>https://doi.org/10.1093/ajh/hpx102</a>
            </li>
            <li>
              Kunutsor, S. K., Khan, H., Zaccardi, F., Laukkanen, T., Willeit, P., & Laukkanen, J. A. (2018). Sauna bathing reduces the risk of stroke in Finnish men and women: A prospective cohort study. Neurology, 90, e1937-e1944. <a onClick={() => window.open('https://www.neurology.org/doi/10.1212/WNL.0000000000005606', '_blank')}>https://doi.org/10.1212/WNL.000000000000560</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};