import { useContext, useEffect, useState } from "react";
import { Timeline, Typography, Button } from "antd";
import { ArrowRightOutlined, BellFilled } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { BlogData } from "../../data/blog.data";

import PhotoHelper from '../../helpers/photo.helper';
import './blogList.scss';
import { SubscribeModal } from "../subscribeModal/subscribeModal.component";
import { UserContext } from "../../contexts/user.context";
import PosthogHelper from "../../helpers/posthog.helper";
import BlogId from "../../enums/blogId.enum";

const { Paragraph, Text } = Typography

export const BlogList = () => {
  const navigate = useNavigate()
  const [openSubscribe, setOpenSubscribe] = useState()
  const { currentUser } = useContext(UserContext)

  useEffect(() => {
    document.title = 'Instalab | Blog'
  }, []);

  useEffect(() => {
    if (currentUser) {
      PosthogHelper.track(currentUser, '[research] view list', true)
    }
  }, [currentUser])

  const TimelineDot = () => {
    return PhotoHelper.format({
      jpg: "/img/heart/timeline-dot.jpg",
      webp: "/img/heart/timeline-dot.webp",
      className: "timeline-dot"
    })
  }

  return (
    <div className="blog-list-component">
      <SubscribeModal
        open={openSubscribe}
        setOpen={setOpenSubscribe}
      />

      <div className="blog-list-header">
        <Paragraph className="blog-list-title">
          Instalab Research
        </Paragraph>
        <Paragraph className="blog-list-description">
          Tailored for busy people who want to make informed health decisions without sacrificing their productivity. Subscribe to gain access to expert knowledge, actionable tips, and the latest health breakthroughs.
        </Paragraph>

        <Button
          type="primary"
          className="subscribe-btn"
          icon={<BellFilled />}
          onClick={() => {
            setOpenSubscribe(true)
            PosthogHelper.track(currentUser, '[research] click subscribe btn', true)
          }}
        >
          Subscribe
        </Button>
      </div>
       <Timeline
          className="blog-timeline"
          items={BlogData.map(({ id, title, description, publishedAt, readTime }) => {
            return {
              dot: <TimelineDot />,
              children: (
                <a onClick={() => {
                  PosthogHelper.track(currentUser, `[research] click on post: ${id}`, true)
                  navigate(`/research/${id}`)
                }}>
                  <div className="timeline-item">
                    <Paragraph className="timeline-title">
                      {title}
                    </Paragraph>
                    <Paragraph className="timeline-description">
                      {description}
                    </Paragraph>
                    <div className="timeline-footer">
                      <Text className="timeline-read-time">{readTime}</Text>
                      <Text>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</Text>
                      <Text className="timeline-published-at">{publishedAt}</Text>
                      <ArrowRightOutlined className="timeline-arrow-icon" />
                    </div>

                  </div>
                </a>
              )
            }
          })
        }
      />
    </div>
  )
}